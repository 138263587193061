import React from "react";
import ContentBlock from "./ContentBlock";
import PortfolioArrows from "./PortfolioArrows";

const ContentContain = (props) => {
  if (props.origin === "PortfolioPage") {
    var arrows = <PortfolioArrows from={props.from}></PortfolioArrows>;
  } else {
    var arrows = null;
  }
  return (
    <div className="content-contain">
      <ContentBlock
        origin={props.origin}
        from={props.from}
      ></ContentBlock>
      {arrows}
    </div>
  );
};

export default ContentContain;