import React from "react";
import LogoHome from "./LogoHome";
import LogoNav from "./LogoNav";
import Menu from "./Menu";
import Burger from "./Burger";
import ContentContain from "./ContentContain";
import Footer from "./Footer";
import HomeGroup from "./HomeGroup";

const Wrapper = (props) => {

    if (props.origin == "Home") {
    var homeGroup = (<HomeGroup/>);
    var homeConceal = {visibility: "hidden",};
  } else {
    var homeGroup = null; 
    var homeConceal = {visibility: "visible",};
  }
  
  return (
    <div id="wrapper" className="main-grid">
      <LogoHome style={homeConceal}></LogoHome>
      <LogoNav style={homeConceal}></LogoNav>
      <Menu style={homeConceal} origin={props.origin}></Menu>
      <Burger style={homeConceal}></Burger>
      <ContentContain origin={props.origin} from={props.from}></ContentContain>
      {/* <HomeGroup /> */}
      {homeGroup}
      <Footer style={homeConceal}></Footer>
    </div>
  );
};

export default Wrapper;



// import React from "react";
// import LogoHome from "./LogoHome";
// import LogoNav from "./LogoNav";
// import Menu from "./Menu";
// import Burger from "./Burger";
// import ContentContain from "./ContentContain";
// import Footer from "./Footer";
// import HomeGroup from "./HomeGroup";

// const Wrapper = (props) => {

//   if (props.origin == "Home") {
//     var homeGroup = (<HomeGroup/>);
//     var logoHome = null; 
//     var logoNav = null; 
//     var menu = null; 
//     var burger = null; 
//     var footer = null; 
//     var contentContain = null; 
//   } else {
//     var homeGroup = null; 
//     var logoHome = (<LogoHome/>);
//     var logoNav = (<LogoNav/>);
//     var menu = (<Menu origin={props.origin}/>);
//     var burger = (<Burger/>);
//     var footer = (<Footer/>);
//     var contentContain = (<ContentContain origin={props.origin} from={props.from}/>);
//   }

//   return (
//     <div id="wrapper" className="main-grid">
//       {logoHome}
//       {logoNav}
//       {menu}
//       {burger}
//       {contentContain}
//       {homeGroup}
//       {footer}
//     </div>
//   );
// };

// export default Wrapper;