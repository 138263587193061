import React, { useRef, useEffect, useMemo } from "react";
import AppJson from "../data/App.json";
import { gsap, Power1 } from "gsap";
import parse from "html-react-parser";

const SkillsItem = () => {
  const timeline = useMemo(() => gsap.timeline({ paused: true }), []);
  const progressbar = [useRef(null)];
  useEffect(() => {
    timeline.set(progressbar.current, {
      width: 0,
      opacity:0,
    });

    timeline.staggerFrom(
      progressbar,
      0.5,
      {
        delay: 0.25,
        width: 0,
        ease: Power1.easeOut,
      },
      0.02
    );

    timeline.play();
  }, [timeline]);
  return (
    <>
      {AppJson.skills.map((skills) => {
        return (
          <div className="skill-group" key={skills.index}>
            <p className="progress-label">{parse(skills.skill)}</p>
            <div className="progress-bar-contain">
              <div
                ref={(div) => (progressbar[skills.index] = div)}
                key={skills.index}
                className="progress-bar"
                style={{ width: skills.percentage + "%" }}
              ></div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SkillsItem;