import React, { useEffect, useRef, useMemo } from "react";
import { gsap } from "gsap";
import GridItems from "./GridItems";
import AppJson from "../data/App.json";
import { useTransition } from "react-route-transition";

// export default function PortfolioGroup() {
const PortfolioGroup = () => {
  const timeline = useMemo(() => gsap.timeline({ paused: true }), []);
  // const timeline = new TimelineMax({ paused: true });
  const portfoliogroupRef = useRef(null);

  useEffect(() => {
    timeline.to(portfoliogroupRef.current, {
      opacity: 1,
      duration: 1,
    });
    timeline.play();
  }, [timeline]);

  useTransition({
    handlers: [
      {
        path: '/portfolio',
        onEnter: async () => {
          console.log('Portfolio-Enter');
          await timeline.to(portfoliogroupRef.current, {
            duration: 1,
            opacity: 1,
            // y: -150,
            // scale:.9,
          })
        },
        onLeave: async () => {
          console.log('Portfolio-Leave');
          await timeline.to(portfoliogroupRef.current, {
            duration: 1,
            opacity: 0,
            // x: -150,
            // scale:1,
          })
        },
      },
    ],
  })

  useEffect(() => {
    var arrange 
    arrange = setTimeout(gridArrange, 10);
    // gridArrange();
    window.addEventListener("resize", gridArrange);
    return () => {
      window.removeEventListener("resize", gridArrange);
    };
  }, []);

  return (
    <div ref={portfoliogroupRef} className="grid-group">
      <GridItems></GridItems>
    </div>
  );
};

export default PortfolioGroup;

// Select the best fitting arangement for the portfolio grid
function gridArrange() {
  let gridGroupClass = document.querySelector(".grid-group");
  let gridItemTally = Object.keys(AppJson.portfolio).length;
  // let gridItemTally = document.getElementsByClassName("grid-item").length;
  let thisManyWide = findMatch(gridItemTally);
  // let columnsValue = "grid-template-columns: repeat(" + thisManyWide + ", 1fr)";
  // gridGroupClass.setAttribute("style", columnsValue);
  let columnsValue = "repeat(" + thisManyWide + ", 1fr)";
  gridGroupClass.style.gridTemplateColumns = columnsValue; 
}

function findMatch(gridItemTally) {
  const nearestValue = (arr, val) =>
    arr.reduce(
      (p, n) => (Math.abs(p) > Math.abs(n - val) ? n - val : p),
      Infinity
    ) + val;
  let ratioWidthPortion = calcRatio(".ratio-contain");
  let dividerAr = [];
  let i;
  for (i = 0; i < gridItemTally; i++) {
    let gridDivider = Math.ceil(gridItemTally / i);
    let gridRatio = i / (gridDivider + i);
    dividerAr.push(gridRatio);
  }
  let closestVal = nearestValue(dividerAr, ratioWidthPortion);
  let closestValIndex = dividerAr.indexOf(closestVal);
  return closestValIndex;
}

function calcRatio(calcClass) {
  let containClassx = document.querySelector(calcClass);
  let containWidth = containClassx.clientWidth;
  let containHeight = containClassx.clientHeight;
  let containWhole = containWidth + containHeight;
  let containWidthPortion = containWidth / containWhole;
  return containWidthPortion;
}