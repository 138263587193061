import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { RouteTransitionProvider } from "react-route-transition";
import Routes from "./Routes";
import Contextall from "../context/Contextall";
// import Div100vh from 'react-div-100vh'

const InitRoutes = () => {
  const [mobileView, setMobileView] = useState(false);
  const [tabletView, setTabletView] = useState(false);
  const [desktopView, setDesktopView] = useState(false);

  const toggleMobileView = () => {
    setMobileView(!mobileView);
  };

  const toggleTabletView = () => {
    setTabletView(!tabletView);
  };

  const toggleDesktopView = () => {
    setDesktopView(!desktopView);
  };

  return (

    <Contextall.Provider
      value={{
        mobileView,
        toggleMobileView,
        tabletView,
        toggleTabletView,
        desktopView,
        toggleDesktopView,
      }}
    >
       {/* <Div100vh> */}
        <Router>
          <RouteTransitionProvider>
            <Routes />
          </RouteTransitionProvider>
        </Router>
      {/* </Div100vh> */}
    </Contextall.Provider>
  );
};

export default InitRoutes;
