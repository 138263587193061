import React, { useRef } from "react";
// import { gsap } from "gsap";
import parse from "html-react-parser";
import AppJson from "../data/App.json";
// import { Scrollbars } from 'react-custom-scrollbars';
// import ScrollContainer from "react-indiana-drag-scroll";
// import ShadowScrollbars from '../dependencies/ShadowScrollbars';
import { FiXSquare } from "react-icons/fi";
import Button from "./Button";

const PortfolioInfo = (props) => {

  // const infoReveal = useMemo(() => gsap.timeline({ paused: true }), []);
  const infoRef = useRef(null);

  // useEffect(() => {
  //   infoReveal.fromTo(infoRef.current, {
  //     autoAlpha: 0,
  //   },{
  //     autoAlpha: 1,
  //     positionX:20,
  //     duration: 1,
  //   });
  // }, []);

  function infoDisplay() {
    var x = document.querySelector(".info");
    if (x.style.display === "none" || x.style.display === "") {
      x.style.display = "block";
      // infoReveal.play(0);
    } else {
      x.style.display = "none";
      // infoReveal.reverse(0);
    }
  }

  
  const portfolioid = props.portfolioid;
  if (AppJson.portfolio[portfolioid].publishers !== "") {
    var publishersA = (
      <div className="category">
        <h3>Publishers:</h3>
        {parse(AppJson.portfolio[portfolioid].publishers)}
      </div>
    );
  } else {
    const publishersA = null; 
  }

  return (
    <div className="info" ref={infoRef}>
      <div className="info-inner">
      {/* <ScrollContainer horizontal={false} className="info-inner scroll-container"> */}
        <span className="hide">
          <h1>{AppJson.portfolio[portfolioid].title}</h1>
        </span>
        {parse(AppJson.portfolio[portfolioid].description)}
        <div className="category">
          <h3>Client:</h3>
          {parse(AppJson.portfolio[portfolioid].client)}
        </div>
        {publishersA}
        <div className="category">
          <h3>Date:</h3>
          {parse(AppJson.portfolio[portfolioid].date)}
        </div>
      {/* </ScrollContainer> */}
      </div>
      <Button buttonStyle="buttons" type="button" onClick={infoDisplay}>
        <FiXSquare color="#000" />
      </Button>
    </div>
  );
};

export default PortfolioInfo;
