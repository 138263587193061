import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LogoH } from "../assets/ui/Basseroni.svg";
import { ReactComponent as LogoV } from "../assets/ui/Basseroni-Vertical.svg";

const LogoHome = (props) => {
  return (
    <div className="logo go-home" style={props.style} >
      <Link className="logo-home" to="/">
        <LogoH className="logo-h" alt="Logo" />
        <LogoV className="logo-v" alt="Logo" />
      </Link>
    </div>
  );
};

export default LogoHome;
