// import * as firebase from "firebase";
import * as firebase from "firebase/app";
import "firebase/auth";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyC5ZJ9zR8GCq-NUYsO3dP1qV4nZfoq_9Do",
  authDomain: "fb-react-folio.firebaseapp.com",
  databaseURL: "https://fb-react-folio.firebaseio.com",
  projectId: "fb-react-folio",
  storageBucket: "fb-react-folio.appspot.com",
  messagingSenderId: "300740676330",
  appId: "1:300740676330:web:3ff05122464d010776a593",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
