import React from "react";
import { NavLink } from "react-router-dom";
// import PanelContext from "../context/PanelContext";
import { useTransitionHistory } from "react-route-transition";

export default function Menu(props) {
  // let location = useLocation();
  // const PanelDrawer = useContext(PanelContext);
  const history = useTransitionHistory();

  // const openContact = () => {
  //   if (location.pathname != "/contact") {
  //     PanelDrawer.openPanelDrawer();
  //   }
  //   mobileNav();
  // };

  // const closeContact = (e) => {
  //   if (PanelDrawer.showDrawer) {
  //     // e.preventDefault();
  //     PanelDrawer.closePanelDrawer();
  //   }
  //   mobileNav();
  // };

  function mobileNav() {
    var x = document.querySelector(".content-block .nav");
    if (x.style.display == "block") {
      x.style.display = "none";
    }
  }

  async function handleHome(e) {
    e.preventDefault();
    mobileNav();
    history.push("/");
  }

  async function handleAbout(e) {
    e.preventDefault();
    mobileNav();
    history.push("/about");
  }

  async function handlePortfolio(e) {
    e.preventDefault();
    mobileNav();
    history.push("/work");
  }

  async function handleContact(e) {
    e.preventDefault();
    mobileNav();
    history.push("/contact");
  }

  return (
    <div className="nav" id={props.origin} style={props.style} >
      <div className={"menu-items"}>
        <NavLink exact to="/" id="home-nav" onClick={handleHome}>
          Home
        </NavLink>
        <NavLink to="/work" id="portfolio-nav" onClick={handlePortfolio}>
          Work
        </NavLink>
        <NavLink to="/about" id="about-nav" onClick={handleAbout}>
          About
        </NavLink>
        <NavLink to="/contact" id="contact-nav" onClick={handleContact}>
          Contact
        </NavLink>
        {/* <NavLink
          // activeClassName="active"
          exact
          to={{ pathname: "/contact", 
          // state: { background: location } 
        }}
          id="contact-nav"
          onClick={handleContact}
          // isActive={(match, location) => {
          //   if (!match) {
          //     return false;
          //   } }}
        >
          Contact
        </NavLink> */}
      </div>
    </div>
  );
}
