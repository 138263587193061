import React, { useRef, useEffect, useMemo, useContext } from "react";
import { gsap } from "gsap";
import AppJson from "../data/App.json";
import Button from "./Button";
import Contextall from "../context/Contextall";

const MockupMenu = (props) => {
  const portfolioid = props.portfolioid;
  const sharedContext = useContext(Contextall);
  const desktopButtonTimeline = useMemo(() => gsap.timeline({ paused: true }),[]);
  const tabletButtonTimeline = useMemo(() => gsap.timeline({ paused: true }),[]);
  const mobileButtonTimeline = useMemo(() => gsap.timeline({ paused: true }),[]);
  const refreshButtonTimeline = useMemo(() => gsap.timeline({ paused: true }),[]);
  // const [mobileBAnimation, setMobileBAnimation] = useState(null);
  // const [tabletBAnimation, setTabletBAnimation] = useState(null);
  // const [desktopBAnimation, setDesktopBAnimation] = useState(null);
  const desktopButtonRef = useRef(null);
  const tabletButtonRef = useRef(null);
  const mobileButtonRef = useRef(null);
  const refreshButtonRef = useRef(null);

  useEffect(() => {
    // setMobileBAnimation(
      mobileButtonTimeline.to(
        mobileButtonRef.current, {
          stroke: "#e6ab4d",
          duration: 0.3,
     })
    //  );
  }, [ mobileButtonTimeline]);

  useEffect(() => {
    // setTabletBAnimation(
      tabletButtonTimeline.to(tabletButtonRef.current, {
        stroke: "#e6ab4d",
        duration: 0.3,
      })
     //);
  }, [tabletButtonTimeline]);

  useEffect(() => {
    // setDesktopBAnimation(
      desktopButtonTimeline.to(desktopButtonRef.current, {
        stroke: "#e6ab4d",
        duration: 0.3,
       })
  //  );
  }, [desktopButtonTimeline]);

  useEffect(() => {
    refreshButtonTimeline.to(refreshButtonRef.current, {
      rotation:360,
      duration: 0.3,
    })
}, [refreshButtonTimeline]);

  useEffect(() => {
    if (sharedContext.mobileView) {
      mobileButtonTimeline.play(0);
    } 
    else{
      mobileButtonTimeline.reverse(0);
    }
  }, [sharedContext.mobileView, mobileButtonTimeline]);

  useEffect(() => {
    if (sharedContext.tabletView) {
      tabletButtonTimeline.play(0);
    }
     else {
      tabletButtonTimeline.reverse(0);
    }
  }, [sharedContext.tabletView, tabletButtonTimeline]);

  useEffect(() => {
    if (sharedContext.desktopView) {
      desktopButtonTimeline.play(0);
    } 
    else{
      desktopButtonTimeline.reverse(0);
    }
  }, [sharedContext.desktopView, desktopButtonTimeline]);

  function mobileView() {
    sharedContext.toggleMobileView();
  }

  function tabletView() {
    sharedContext.toggleTabletView();
  }

  function desktopView() {
    sharedContext.toggleDesktopView();
  }

  function refreshDevices() {
    var targetx = document.getElementsByClassName("iframe-all");
    for (var targeti = 0; targeti < targetx.length; targeti++) {
      targetx[targeti].src = targetx[targeti].src;
      // targetx[targeti].classList.add("animate_op");
    }
    refreshButtonTimeline.play(0);
  }

  if (AppJson.portfolio[portfolioid].notIframe) {
    var reloadB = null;
  } else {
    var reloadB = (
      <Button buttonStyle="buttons" type="button" onClick={refreshDevices}>
        {/* <FiRefreshCw  /> */}
        <svg ref={refreshButtonRef} stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>
      </Button>
    );
  }

  if (AppJson.portfolio[portfolioid].responsive) {
    var responsive = (
      <>
        <Button
          buttonStyle="buttons settings-Group"
          type="button"
          onClick={desktopView}  
        >
          {/* <FiMonitor /> */}
          <svg ref={desktopButtonRef} stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect><line x1="8" y1="21" x2="16" y2="21"></line><line x1="12" y1="17" x2="12" y2="21"></line></svg>
        </Button>
        <Button
          buttonStyle="buttons settings-Group"
          type="button"
          onClick={tabletView} 
        >
          {/* <FiTablet  /> */}
          <svg ref={tabletButtonRef} stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><rect x="4" y="2" width="16" height="20" rx="2" ry="2" transform="rotate(180 12 12)"></rect><line x1="12" y1="18" x2="12" y2="18"></line></svg>
        </Button>
        <Button
          buttonStyle="buttons settings-Group"
          type="button"
          onClick={mobileView}      
        >
          {/* <FiSmartphone  /> */}
          <svg ref={mobileButtonRef} stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect>
          {/* <line x1="12" y1="18" x2="12" y2="18"></line> */}
          </svg>
        </Button>
      </>
    );
  } else {
    var responsive = null;
  }

  return (
    <div className="mockupnav">
      {responsive}
      {reloadB}
      {/*<Button onClick={zoomDisplay} buttonStyle="buttons expand" type="button"><FiPlusSquare /></Button>*/}
    </div>
  );
};

export default MockupMenu;
