import React from "react";
// import ScrollContainer from "react-indiana-drag-scroll";

const AboutProfile = () => {
  return (
    <>
      <div className="profile">
        <h1 className="hide">Profile</h1>
        {/* <h2>Welcome to my little piece of the web.</h2> */}
        <p>
          Hello and thanks for visiting. You're viewing the work of Adam<span className="conceal">i</span>Basser. A multidisciplinary digital designer and front-end developer based in Sydney.
        </p>
        <p>
        A balanced skill-set across design / multimedia and web is where I believe I can add value, and I revel in applying and expanding my capabilities in both areas.
        </p>
        <p>
          I’m inspired to create progressive design, effective UI with slick interactions, and always to know and deliver the ideal solution to elated clients.
        </p>
      </div>
    </>
  );
};

export default AboutProfile;
