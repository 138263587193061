import React, { useEffect } from "react";
// import { useHistory } from "react-router-dom";
import AppJson from "../data/App.json";
import { FiChevronLeft } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";
import { FiGrid } from "react-icons/fi";
import Button from "./Button";
import { useTransitionHistory } from "react-route-transition";

const FolioNav = (props) => {
  // const history = useHistory();
  const portfolioid = props.portfolioid;
  const currentPage = Number(portfolioid);
  const newPageForward = currentPage + 1;
  const newPageBackward = currentPage - 1;
  const portfolioLength = Object.keys(AppJson.portfolio).length;
  const history = useTransitionHistory();

  useEffect(() => {
    const navBack = document.querySelector(".folionav .nav-left");
    const navNext = document.querySelector(".folionav .nav-right");
    if (portfolioid == 0) {
      navBack.classList.add("disabled");
    } else if (portfolioid == portfolioLength - 1) {
      navNext.classList.add("disabled");
    }
  }, [portfolioid, portfolioLength]);

  async function navigateForward(e) {
    e.preventDefault();
    if (portfolioid < portfolioLength - 1) {
      history.push("/work/" + AppJson.portfolio[newPageForward].pageUrl);
    }
  }

  async function navigateBackward(e) {
    e.preventDefault();
    if (portfolioid > 0) {
      history.push("/work/" + AppJson.portfolio[newPageBackward].pageUrl);
    }
  }

  async function folioGrid(e) {
    e.preventDefault();
    history.push("/work");
  }

  return (
    <div className="folionav">
      <Button
        buttonStyle="buttons nav-left"
        type="button"
        onClick={navigateBackward}
      >
        <FiChevronLeft />
      </Button>
      <Button buttonStyle="buttons" type="button" onClick={folioGrid}>
        <FiGrid />
      </Button>
      <Button
        buttonStyle="buttons nav-right"
        type="button"
        onClick={navigateForward}
      >
        <FiChevronRight />
      </Button>
    </div>
  );
};

export default FolioNav;
