import React, { useState } from "react";
import { useForm } from "react-hook-form";
import firebase from "../dependencies/firebase";

const Contact = (props) => {
  const { register, onContactSubmit, errors } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    data.preventDefault();
  };

  // const onContactSubmit = (event) => {
  //   event.preventDefault();
  // }
  const [mobileNum, setMobileNum] = useState(" ");
  const mobileNumInput = (event) => {
    setMobileNum(event.target.value);
  };

  const [otpNum, setOtpNum] = useState(" ");
  const otpNumInput = (event) => {
    setOtpNum(event.target.value);
  };

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignInSubmit();
        },
      }
    );
  };

  const onSignInSubmit = (event) => {
    event.preventDefault();
    setUpRecaptcha();
    // var phoneNumber = getPhoneNumberFromUserInput();
    // var phoneNumber = "+911234567899";
    var phoneNumber = mobileNum;
    console.log("number = " + mobileNum);
    var appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(function (confirmationResult) {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // var code = getCodeFromUserInput();
        var code = window.prompt("Enter OTP");
        // var code = otpNum;
        confirmationResult
          .confirm(code)
          .then(function (result) {
            // User signed in successfully.
            var user = result.user;
            // ...
            console.log("OTP number = " + otpNum);
            console.log("User is signed in");
          })
          .catch(function (error) {
            // User couldn't sign in (bad verification code?)
            // ...
            console.log(
              "User couldnt sign in - possible bad verification code"
            );
          });
      })
      .catch(function (error) {
        // Error; SMS not sent
        // ...
        console.log("Error; SMS not sent");
      });
  };

  return (
    <div className={props.cName}>
      <h1 className="hide">Contact</h1>

      <form className="contact-f" onSubmit={onContactSubmit}>
        <p className="third-to-full-width">
          <label>First Name</label>
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            maxLength="20"
            ref={register({
              required: true,
              maxLength: 20,
              pattern: {
                value: /^[A-Za-z]+$/i,
                message: "invalid characters",
              },
            })}
          />
          {errors.firstName && <span>{errors.firstName.message}</span>}
        </p>
        <p className="third-to-full-width">
          <label>Last Name</label>
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            maxLength="20"
            ref={register({
              required: true,
              maxLength: 20,
              pattern: {
                value: /^[A-Za-z]+$/i,
                message: "invalid characters",
              },
            })}
          />
          {errors.lastName && <span>{errors.lastName.message}</span>}
        </p>
        <p className="third-to-full-width">
          <label>Email Address</label>

          <input
            type="text"
            name="email"
            placeholder="Email Address"
            maxLength="50"
            ref={register({
              // required: "Required",
              required: true,
              maxLength: 20,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address",
              },
            })}
          />
          {errors.email && <span>{errors.email.message}</span>}
        </p>

        <p className="full-width">
          <label>Message</label>
          <textarea
            type="text"
            name="message"
            placeholder="Message"
            maxLength="1000"
            ref={register({ required: true, maxLength: 1000 })}
          />
        </p>
        <div className="two-thirds-to-full-width"></div>
        <p className="third-to-full-width">
          <label>Verification Code</label>
          <input
            type="text"
            name="verificationCode"
            placeholder="Verification Code"
            maxLength="6"
            ref={register({
              required: true,
              maxLength: 6,
              pattern: {
                value: /^[0-9]{5}$/i,
                message: "invalid characters",
              },
            })}
            onChange={otpNumInput}
          />
        </p>
        <p className="full-width">
          <input type="submit" value="Send" />
          {/* <button>Send</button> */}
        </p>
      </form>
      <form onSubmit={onSignInSubmit} className="contact-f contact-aut">
        <div id="recaptcha-container"></div>
        <p className="authorise-1">
          <label>Mobile Number</label>
          <input
            type="text"
            name="mobileNumber"
            placeholder="Mobile Number"
            maxLength="10"
            ref={register({
              required: true,
              maxLength: 10,
              pattern: {
                value: /^04[0-9]{8}$/i,
                message: "invalid characters",
              },
            })}
            onChange={mobileNumInput}
          />
        </p>
        <p className="authorise-2">
          <input type="submit" value="Verify" />
        </p>
      </form>
    </div>
  );
};

export default Contact;
