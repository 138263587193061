import React from "react";
import SkillsItems from "./SkillsItems";

const AboutSkills = () => {
  return (
    <div className="skills">
      <h1 className="hide">Skills</h1>
      <SkillsItems></SkillsItems>
    </div>
  );
};

export default AboutSkills;
