import React from "react";
// import HomeGroup from "./HomeGroup";
import AboutGroup from "./AboutGroup";
import ContactGroup from "./ContactGroup";
import PortfolioGroup from "./PortfolioGroup";
import PortfolioPageGroup from "./PortfolioPageGroup";
import NotFound from "./NotFound";

const Template = (props) => {
  return (
    <>
      {(() => {
        switch (props.origin) {
          // case "Home":
          //   return <HomeGroup />;
          case "Contact":
            return <ContactGroup />;
          case "About":
            return <AboutGroup />;
          case "Portfolio":
            return <PortfolioGroup />;
          case "PortfolioPage":
            return <PortfolioPageGroup from={props.from} />;
          case "NotFound":
            return <NotFound />;
            // default:
            //   return <HomeGroup />;
        }
      })()}
    </>
  );
};

export default Template;
