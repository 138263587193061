import React, { useRef } from "react";
import { useCircularInputContext, useCircularDrag } from "react-circular-input";

const CircularThumbCustom = () => {
	
//   function rotate(cx, cy, x, y, angle) {
//     var radians = (Math.PI / 180) * angle,
//       cos = Math.cos(radians),
//       sin = Math.sin(radians),
//       nx = cos * (x - cx) + sin * (y - cy) + cx,
//       ny = cos * (y - cy) - sin * (x - cx) + cy;
//     console.log(nx);
//     console.log(ny);
//     return [nx, ny];
//   }

  const { getPointFromValue, center } = useCircularInputContext();
  const { x, y } = getPointFromValue();
//   const cx = center.x;
//   const cy = center.y;
//   const angle = -90;
//   var nx;
//   var ny;

//   rotate(cx, cy, x, y, angle);

  const ref = useRef(null);
  useCircularDrag(ref);
//   console.log(center);
//   console.log(cx);
//   console.log(cy);
//   console.log(nx);
//   console.log(ny);

  return (
    <line ref={ref} x1={x} y1={y} x2={x} y2={y} className="custom-thumb-new" />
  );
};

export default CircularThumbCustom;