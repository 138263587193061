import React from "react";
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  // useLocation,
} from "react-router-dom";
import Wrapper from "./Wrapper";
// import ContactGroup from "./ContactGroup";
import "../styles/App.css";
import AppJson from "../data/App.json";

export default function Routes() {
  // let location = useLocation();
  // let background = location.state && location.state.background;

  return (
    <>
      {/* <Switch location={background || location}> */}
      <Switch>
        {AppJson.portfolio.map((portfolio) => {
          return (
            <Route exact path={"/work/" + portfolio.pageUrl} key={portfolio.index}>
              <Wrapper origin={"PortfolioPage"} from={portfolio.index} />
            </Route>
          );
        })}
        <Route exact path="/contact">
          <Wrapper origin={"Contact"} />
        </Route>
        <Route exact path="/work">
          <Wrapper origin={"Portfolio"} />
        </Route>
        <Route exact path="/about">
          <Wrapper origin={"About"} />
        </Route>
        <Route exact path="/">
          <Wrapper origin={"Home"} />
        </Route>
        <Route path="/*">
          <Wrapper origin={"NotFound"} />
        </Route>
        <Route path="*">
          <Wrapper origin={"NotFound"} />
        </Route>
      </Switch>
      {/* Show the modal when a background page is set */}
      {/* {background && <Route path="/contact" children={<ContactGroup />} />} */}
      {/* {background && <Route exact path="/contact"></Route>} */}
    </>
  );
}
