import React, { useEffect, useRef, useMemo } from "react";
import { gsap, Power1 } from "gsap";

const NotFound = () => {
  const timeline = useMemo(() => gsap.timeline(), []);
  const notfoundRef = useRef(null);

  useEffect(() => {
    timeline
      .to(
        notfoundRef.current,
        {
          opacity: 1,
          delay: 0.25,
          duration: 0.5,
          ease: Power1.easeIn,
        },
        0
      )
      .play();
    return () => {};
  }, [timeline]);

  return (
    <div className="home-group" ref={notfoundRef}>
      <p className="ab-center">404 Page Not Found</p>
    </div>
  );
};

export default NotFound;
