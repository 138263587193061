import React from "react";
import AppJson from "../data/App.json";
import IframeResizer from "iframe-resizer-react";
// import ScrollContainer from "react-indiana-drag-scroll";
import { useEffect } from "react";

const Iframer = (props) => {

  useEffect(() => {
    setTimeout(function(){     var targetx = document.getElementsByClassName("iframe-all");
    for (var targeti = 0; targeti < targetx.length; targeti++) {
      targetx[targeti].src = targetx[targeti].src;
    }; }, 100);
  }, []);

  const portfolioid = props.portfolioid;

  if (AppJson.portfolio[portfolioid].notIframe) {
    var ifOrImg = (
      <img
        src={
          "/assets/portfolio/" +
          AppJson.portfolio[portfolioid].pageUrl +
          "/assets/ui/" +
          AppJson.portfolio[portfolioid].thumbSrc +
          ".png"
        }
        className={AppJson.portfolio[portfolioid].fgClass + " " + "web-fg-all"}
        alt={AppJson.portfolio[portfolioid].thumbAlt}
      />
    );
  } else {
    var ifOrImg = (
      <IframeResizer
        heightCalculationMethod={AppJson.portfolio[portfolioid].heightCM}
        className={AppJson.portfolio[portfolioid].fgClass + " " + props.cnameIF}
        src={
          AppJson.portfolio[portfolioid].iframeUrl === ""
            ? "/assets/portfolio/" +
              AppJson.portfolio[portfolioid].pageUrl +
              "/index.htm"
            : AppJson.portfolio[portfolioid].iframeUrl
        }
        scrolling='no'
        // minHeight="1000px"
        // log
        data-width-ratio={props.dwidth}
        // style={{ minWidth: '100%'}}
        // style={{ minHeight: '1000px'}}
      />
    );
  }

  return (
    <React.Fragment>
      <div
        style={
          AppJson.portfolio[portfolioid].backgroundImg
            ? {
                backgroundImage:
                  "url(" +
                  "/assets/portfolio/" +
                  AppJson.portfolio[portfolioid].pageUrl +
                  "/assets/ui/Background.png" +
                  ")",
              }
            : { backgroundImage: "none" }
        }
        className={
          AppJson.portfolio[portfolioid].bgClass +
          " " +
          props.cnameIFC +
          " " +
          "web-bg-all"
        }
      />
      {/* <ScrollContainer component="iframe" horizontal={false} nativeMobileScroll={false} className={props.cnameIFC} portfolioid={portfolioid}> */}
      <div className={props.cnameIFC} portfolioid={portfolioid}>
        {ifOrImg}
      {/* </ScrollContainer> */}
      </div>
    </React.Fragment>
  );
};

export default Iframer;
