import React, { useEffect } from "react";
import Template from "./Template";
import Menu from "./Menu";
// import ContactPanel from "./ContactPanel";
// import PortfolioZoom from "./PortfolioZoom";

const ContentBlock = (props) => {
  useEffect(() => {
    createBlocks();
    window.addEventListener("resize", createBlocks);
  }, []);

  // useEffect(() => {
  //   var create 
  //   create = setTimeout(createBlocks, 5);
  //   // gridArrange();
  //   window.addEventListener("resize", createBlocks);
  // }, []);

  // if (props.origin != "Contact") {
  //   var contactPanel = <ContactPanel></ContactPanel>;
  // }

  return (
    <div className="ratio-contain">
      <svg className="maintain-ratio" />
      <div className="content-block">
        <Template origin={props.origin} from={props.from}></Template>
        {/* {contactPanel} */}
        <Menu origin={props.origin}></Menu>
        {/*<PortfolioZoom from={props.from}></PortfolioZoom>*/}
        <div id="placeholder"></div>
      </div>
    </div>
  );
};

export default ContentBlock;

// Select the best fitting content block

var widthDifAr;
var scalerAr;
var wrapClass;
var containClass;
var ratioContain;
var ratioMaintain;
var contentContainHeight;
var contentContainWidth;
var containWidthPortion;
var landscapeObj;
// var minLandscapeObj;
var portraitObj;
var minPortraitObj;
var landscapeExtObj;
// var minLandscapeExtObj;
var portraitExtObj;
var minPortraitExtObj;
var scalerSelect;
var lowestWidthDif;
var newObjWidthDif;

function createBlocks() {
  widthDifAr = [];
  scalerAr = [];
  const placeholder = document.getElementById("placeholder");
  const vh = placeholder.getBoundingClientRect().height;
  const vw = placeholder.getBoundingClientRect().width;

  // Portrait
  if (vh / vw >= 1) {
    var gap = (vh * 100) / 60;
    // var panel = 15 + vh * 1.5;
    var panel = 20 + vh * 1.4;
  }
  // Landscape
  else if (vh / vw < 1) {
    var gap = (vw * 100) / 60;
    // var panel = 15 + vw * 1.5;
    var panel = 20 + vw * 1.4;
  }

  let root = document.documentElement;
  root.style.setProperty("--gap", gap + "px");
  root.style.setProperty("--panel", panel + "px");
  wrapClass = document.querySelector("#wrapper");
  containClass = document.querySelector(".content-contain");
  ratioContain = document.querySelector(".ratio-contain");
  ratioMaintain = document.querySelector(".maintain-ratio");
  var logoH = document.querySelector(".launch-nav svg.logo-h");
  var rect = logoH.getBoundingClientRect();
  // get the width of svg logo-h
  var logoWi = rect.width;
  // get the inner width of wrapper
  var wrapInner = wrapClass.clientWidth - gap * 2;
  // Work out the desired header height based on ratio
  var headerH = (wrapInner / 3.5) / vh;
  // if theyre equal then
  if (logoWi + 1 >= wrapInner) {
    // set the css property
    // Apply in an appropriate media query to effect portrait view
    root.style.setProperty("--headerh", headerH + "vh");
    wrapClass.classList.add("scale-header");
    logoH.setAttribute("style", "width: 100%");
    logoH.setAttribute("style", "max-height: none");
  } else {
    root.style.setProperty("--headerh", "20vh");
    wrapClass.classList.remove("scale-header");
    logoH.removeAttribute("style", "width: 100%");
    logoH.removeAttribute("style", "max-height: none");
  }
  // Additionally add a media query based on ratio within portrait for an oversized footer mode

  contentContainHeight = containClass.clientHeight;
  contentContainWidth = containClass.clientWidth;
  containWidthPortion =
    contentContainWidth / (contentContainWidth + contentContainHeight);

  landscapeObj = {
    width: 2560,
    height: 1408,
    spacerX: gap,
    spacerY: 0,
    panelX: 0,
    panelY: 0,
    class: "landscape",
  };
  // minLandscapeObj = {
  //   width: 1840,
  //   height: 1520,
  //   spacerX: gap,
  //   spacerY: 0,
  //   panelX: panel,
  //   panelY: 0,
  //   class: "minimal-landscape",
  // };
  portraitObj = {
    width: 1460,
    height: 1910,
    spacerX: 0,
    spacerY: gap * 2,
    panelX: 0,
    panelY: panel,
    class: "portrait",
  };
  minPortraitObj = {
    width: 3130,
    height: 2584,
    spacerX: 0,
    spacerY: gap,
    panelX: 0,
    panelY: panel,
    class: "minimal-portrait",
  };
  landscapeExtObj = {
    width: 2550,
    height: 1405,
    spacerX: gap,
    spacerY: gap,
    panelX: 0,
    panelY: panel,
    class: "landscape-ext",
  };
  // minLandscapeExtObj = {
  //   width: 1980,
  //   height: 1636,
  //   spacerX: gap,
  //   spacerY: gap,
  //   panelX: panel,
  //   panelY: panel,
  //   class: "minimal-landscape-ext",
  // };
  portraitExtObj = {
    width: 1430,
    height: 2370,
    spacerX: 0,
    spacerY: gap * 2,
    panelX: 0,
    panelY: panel,
    class: "portrait-ext",
  };
  minPortraitExtObj = {
    width: 3130,
    height: 2585,
    spacerX: 0,
    spacerY: gap * 2,
    panelX: 0,
    panelY: panel * 2,
    class: "minimal-portrait-ext",
  };
  determineSize(
    landscapeObj.width,
    landscapeObj.height,
    landscapeObj.spacerX,
    landscapeObj.spacerY,
    landscapeObj.panelX,
    landscapeObj.panelY
  );
  // determineSize(
  //   minLandscapeObj.width,
  //   minLandscapeObj.height,
  //   minLandscapeObj.spacerX,
  //   minLandscapeObj.spacerY,
  //   minLandscapeObj.panelX,
  //   minLandscapeObj.panelY
  // );
  determineSize(
    portraitObj.width,
    portraitObj.height,
    portraitObj.spacerX,
    portraitObj.spacerY,
    portraitObj.panelX,
    portraitObj.panelY
  );
  determineSize(
    minPortraitObj.width,
    minPortraitObj.height,
    minPortraitObj.spacerX,
    minPortraitObj.spacerY,
    minPortraitObj.panelX,
    minPortraitObj.panelY
  );
  determineSize(
    landscapeExtObj.width,
    landscapeExtObj.height,
    landscapeExtObj.spacerX,
    landscapeExtObj.spacerY,
    landscapeExtObj.panelX,
    landscapeExtObj.panelY
  );
  // determineSize(
  //   minLandscapeExtObj.width,
  //   minLandscapeExtObj.height,
  //   minLandscapeExtObj.spacerX,
  //   minLandscapeExtObj.spacerY,
  //   minLandscapeExtObj.panelX,
  //   minLandscapeExtObj.panelY
  // );
  determineSize(
    portraitExtObj.width,
    portraitExtObj.height,
    portraitExtObj.spacerX,
    portraitExtObj.spacerY,
    portraitExtObj.panelX,
    portraitExtObj.panelY
  );
  determineSize(
    minPortraitExtObj.width,
    minPortraitExtObj.height,
    minPortraitExtObj.spacerX,
    minPortraitExtObj.spacerY,
    minPortraitExtObj.panelX,
    minPortraitExtObj.panelY
  );
}

function determineSize(
  objWidth,
  objHeight,
  objSpacerX,
  objSpacerY,
  objPanelX,
  objPanelY
) {
  let newObjWidth = contentContainWidth - objSpacerX - objPanelX;
  let newObjWidthDifY = objWidth / newObjWidth;
  let newObjHeight = objHeight / newObjWidthDifY + objSpacerY + objPanelY;

  if (contentContainHeight >= newObjHeight) {
    newObjWidthDif = Math.abs(
      newObjWidth / (newObjWidth + objSpacerX + objPanelX + newObjHeight) -
        containWidthPortion
    );

    let objWidthCalc =
      newObjWidth / (newObjWidth + objSpacerX + objPanelX + newObjHeight);
    widthDifAr.push(newObjWidthDif);
    scalerAr.push("scaleHeight");
  } else if (contentContainHeight < newObjHeight) {
    let newObjHeight = contentContainHeight - objSpacerY - objPanelY;
    let newObjHeightDifX = objHeight / newObjHeight;
    let newObjWidth = objWidth / newObjHeightDifX + objSpacerX + objPanelX;
    newObjWidthDif = Math.abs(
      newObjWidth / (newObjWidth + objSpacerX + objPanelX + newObjHeight) -
        containWidthPortion
    );

    let objWidthCalc =
      newObjWidth / (newObjWidth + objSpacerX + objPanelX + newObjHeight);
    widthDifAr.push(newObjWidthDif);
    scalerAr.push("scaleWidth");
  }
  if (widthDifAr.length === 6) {
    var lowestWidthDifC = Math.min.apply(Math, widthDifAr);
    lowestWidthDif = widthDifAr.indexOf(lowestWidthDifC);
    scalerSelect = scalerAr[lowestWidthDif];
    selectBestFit(lowestWidthDif, scalerSelect);
    widthDifAr = [];
    scalerAr = [];
  }
}

function selectBestFit(winner, scaler) {
  if (winner === 0) {
    applyBestFit("landscape", scalerSelect);
  } 
  // else if (winner === 1) {
  //   applyBestFit("minLandscape", scalerSelect);
  // } 
  else if (winner === 1) {
    applyBestFit("portrait", scalerSelect);
  } else if (winner === 2) {
    applyBestFit("minPortrait", scalerSelect);
  } else if (winner === 3) {
    applyBestFit("landscapeExt", scalerSelect);
  } 
  // else if (winner === 5) {
  //   applyBestFit("minLandscapeExt", scalerSelect);
  // } 
  else if (winner === 4) {
    applyBestFit("portraitExt", scalerSelect);
  } else if (winner === 5) {
    applyBestFit("minPortraitExt", scalerSelect);
  }
}

// Apply the style, width and height for the best fitting content block

function applyBestFit(setBlock, scaler) {
  let blocksAr = [
    "landscape",
    // "minLandscape",
    "portrait",
    "minPortrait",
    "landscapeExt",
    // "minLandscapeExt",
    "portraitExt",
    "minPortraitExt",
  ];
  let locateSetBlock = blocksAr.indexOf(setBlock);
  blocksAr.splice(locateSetBlock, 1);
  var i;
  for (i = 0; i <= blocksAr.length - 1; i++) {
    wrapClass.classList.remove(eval(blocksAr[i] + "Obj.class"));
  }
  ratioMaintain.setAttribute("width", eval(setBlock + "Obj.width"));
  ratioMaintain.setAttribute("height", eval(setBlock + "Obj.height"));
  wrapClass.classList.add(eval(setBlock + "Obj.class"));
  if (scaler === "scaleHeight") {
    ratioMaintain.style.width =
      contentContainWidth -
      eval(setBlock + "Obj.spacerX") -
      eval(setBlock + "Obj.panelX") +
      "px";
    ratioMaintain.style.height = "auto";

    containClass.classList.remove("scale-width");
    containClass.classList.add("scale-height");
  } else if (scaler === "scaleWidth") {
    ratioMaintain.style.height =
      contentContainHeight -
      eval(setBlock + "Obj.spacerY") -
      eval(setBlock + "Obj.panelY") +
      "px";
    ratioMaintain.style.width = "auto";

    containClass.classList.add("scale-width");
    containClass.classList.remove("scale-height");
  }
  containClass.setAttribute("style", "visibility: visible");
}
