import React, { useRef, useEffect, useMemo } from "react";
import Contact from "./Contact";
import { useTransition } from "react-route-transition";
import { gsap } from "gsap";

const ContactGroup = (props) => {
  const contactgroupRef = useRef(null);
  const timeline = useMemo(() => gsap.timeline({ paused: true }), []);

  useEffect(() => {
    timeline.to(contactgroupRef.current, {
      opacity: 1,
      duration: 1,
    });
    timeline.play();
  }, [timeline]);

  useTransition({
    handlers: [
      {
        path: "/contact",
        onEnter: async () => {
          console.log('Contact-Enter');
          await timeline.to(contactgroupRef.current, {
            duration: 1,
            opacity: 1,
            // y: -150,
            // scale:.9,
          });
        },
        onLeave: async () => {
          console.log('Contact-Leave');
          await timeline.to(contactgroupRef.current, {
            duration: 1,
            opacity: 0,
            // x: -150,
            // scale:1,
          });
        },
      },
    ],
  });

  return (
    <div ref={contactgroupRef} className="contact-group">
      <Contact cName="contact"></Contact>
    </div>
  );
};

export default ContactGroup;