import React, { useRef, useEffect, useMemo, useContext, useState } from "react";
import { gsap } from "gsap";
import Iframer from "./Iframer";
import AppJson from "../data/App.json";
import { ReactComponent as MockupBG } from "../assets/ui/Mockup-BG.svg";
import Contextall from "../context/Contextall";

const PortfolioMockup = (props) => {
  const sharedContext = useContext(Contextall);
  const portfolioid = props.portfolioid;
  const desktopToMobile = useMemo(() => gsap.timeline({ paused: true }), []);
  const desktopToTablet = useMemo(() => gsap.timeline({ paused: true }), []);
  const mobileToTablet = useMemo(() => gsap.timeline({ paused: true }), []);
  const desktopToReset = useMemo(() => gsap.timeline({ paused: true }), []);
  const tabletToReset = useMemo(() => gsap.timeline({ paused: true }), []);
  const mobileToReset = useMemo(() => gsap.timeline({ paused: true }), []);
  const resetAll = useMemo(() => gsap.timeline({ paused: true }), []);
  const [animationState, setAnimationState] = useState("reset");
  const desktopGroupRef = useRef(null);
  const tabletGroupRef = useRef(null);
  const mobileGroupRef = useRef(null);
  const mockupRef = useRef(null);

  function desktopViewFalse() {
    if (sharedContext.desktopView) {
      sharedContext.toggleDesktopView();
    }
  }

  function tabletViewFalse() {
    if (sharedContext.tabletView) {
      sharedContext.toggleTabletView();
    }
  }

  function mobileViewFalse() {
    if (sharedContext.mobileView) {
      sharedContext.toggleMobileView();
    }
  }

useEffect(() => {

desktopToMobile
.fromTo(mockupRef.current, {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",},
  {scale: 2.25,xPercent: 34,yPercent: -54,rotationY: 0.01,force3D:"auto",duration: 0.3,},'0')
  .fromTo(tabletGroupRef.current, {scale: 1,xPercent: -100,yPercent: 0,rotationY: 0.01,force3D:"auto",},
    {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",duration: 0.3,},'0')
    .fromTo(mobileGroupRef.current, {scale: 1,xPercent: -100,yPercent: 0,rotationY: 0.01,force3D:"auto",},
      {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",duration: 0.3,delay:0.1,},'0'); 

desktopToTablet
.fromTo(mockupRef.current, {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",},
  {scale: 1.44,xPercent: 22,yPercent: -17,rotationY: 0.01,force3D:"auto",duration: 0.3,},'0')
  .fromTo(tabletGroupRef.current, {scale: 1,xPercent: -100,yPercent: 0,rotationY: 0.01,force3D:"auto",},
    {scale: 1,xPercent: 12.5,yPercent: 0,rotationY: 0.01,force3D:"auto",duration: 0.3,},'0')
    .fromTo(mobileGroupRef.current, {scale: 1,xPercent: -100,yPercent: 0,rotationY: 0.01,force3D:"auto",},
      {scale: 1,xPercent: -100,yPercent: 0,rotationY: 0.01,force3D:"auto",duration: 0.3,delay:0.1,},'0'); 

mobileToTablet
.fromTo(mockupRef.current, {scale: 2.25,xPercent: 34,yPercent: -54,rotationY: 0.01,force3D:"auto",},
  {scale: 1.44,xPercent: 22,yPercent: -17,rotationY: 0.01,force3D:"auto",duration: 0.3,},'0')
  .fromTo(tabletGroupRef.current, {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",},
    {scale: 1,xPercent: 12.5,yPercent: 0,rotationY: 0.01,force3D:"auto",duration: 0.3,},'0')
    .fromTo(mobileGroupRef.current, {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",},
      {scale: 1,xPercent: -100,yPercent: 0,rotationY: 0.01,force3D:"auto",duration: 0.3,delay:0.1,},'0'); 

mobileToReset
.fromTo(mockupRef.current, {scale: 2.25,xPercent: 34,yPercent: -54,rotationY: 0.01,force3D:"auto",},
  {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",duration: 0.3,},'0')
  .fromTo(tabletGroupRef.current, {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",},
    {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",duration: 0.3,},'0')
    .fromTo(mobileGroupRef.current, {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",},
      {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",duration: 0.3,delay:0.1,},'0'); 

  desktopToReset
.fromTo(mockupRef.current, {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",},
  {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",duration: 0.3,},'0')
  .fromTo(tabletGroupRef.current, {scale: 1,xPercent: -100,yPercent: 0,rotationY: 0.01,force3D:"auto",},
    {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",duration: 0.3,},'0')
    .fromTo(mobileGroupRef.current, {scale: 1,xPercent: -100,yPercent: 0,rotationY: 0.01,force3D:"auto",},
      {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",duration: 0.3,delay:0.1,},'0'); 

      tabletToReset
.fromTo(mockupRef.current, {scale: 1.44,xPercent: 22,yPercent: -17,rotationY: 0.01,force3D:"auto",},
  {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",duration: 0.3,},'0')
  .fromTo(tabletGroupRef.current, {scale: 1,xPercent: 12.5,yPercent: 0,rotationY: 0.01,force3D:"auto",},
    {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",duration: 0.3,},'0')
    .fromTo(mobileGroupRef.current, {scale: 1,xPercent: -100,yPercent: 0,rotationY: 0.01,force3D:"auto",},
      {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",duration: 0.3,delay:0.1,},'0'); 

      resetAll
      .from(mockupRef.current, {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",},'0')
        .from(tabletGroupRef.current, {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",},'0')
          .from(mobileGroupRef.current, {scale: 1,xPercent: 0,yPercent: 0,rotationY: 0.01,force3D:"auto",},'0'); 

  }, [desktopToMobile, desktopToTablet, mobileToTablet, mobileToReset, desktopToReset, tabletToReset, resetAll]);

  useEffect(() => {

    // console.log('sharedContext.mobileView = '+ sharedContext.mobileView);
    // console.log('sharedContext.tabletView = '+ sharedContext.tabletView);
    // console.log('sharedContext.desktopView = '+ sharedContext.desktopView);

    if (sharedContext.mobileView && (!sharedContext.desktopView || !sharedContext.tabletView)) {
      tabletViewFalse();
      desktopViewFalse();
if(animationState === 'reset'){
  mobileToReset.reverse(0);
} else if(animationState === 'desktop'){
  desktopToMobile.play(0);
} else if(animationState === 'tablet'){
  mobileToTablet.reverse(0);
};
setAnimationState('mobile');
    };
    return () => {      
      mobileViewFalse()
    };
  }, [sharedContext.mobileView]);

  useEffect(() => {
    if (sharedContext.tabletView && (!sharedContext.mobileView || !sharedContext.desktopView)) {
      mobileViewFalse();
      desktopViewFalse();
      if(animationState === 'reset'){
        tabletToReset.reverse(0);
      } else if(animationState === 'desktop'){
        desktopToTablet.play(0);
      } else if(animationState === 'mobile'){
        mobileToTablet.play(0);
      };
      setAnimationState('tablet');
    };
    return () => {      
      tabletViewFalse()
    };
  }, [sharedContext.tabletView]);

  useEffect(() => {
    if (sharedContext.desktopView && (!sharedContext.mobileView || !sharedContext.tabletView)) {
      mobileViewFalse();
      tabletViewFalse();
      if(animationState === 'reset'){
        desktopToReset.reverse(0);
      } else if(animationState === 'mobile'){
        desktopToMobile.reverse(0);
      } else if(animationState === 'tablet'){
        desktopToTablet.reverse(0);
      };
      setAnimationState('desktop');
    };
    return () => {      
      desktopViewFalse()
    };
  }, [sharedContext.desktopView]);

  useEffect(() => {

    if (!sharedContext.mobileView && !sharedContext.desktopView && !sharedContext.tabletView) {
        if(animationState ==='mobile'){
          mobileToReset.play(0);
        } else if(animationState ==='desktop'){
          desktopToReset.play(0);
        } else if(animationState ==='tablet'){
          tabletToReset.play(0);
        } else if(animationState ==='reset'){
          console.log('Reset');
        };
        setAnimationState('reset');
    };
  }, [sharedContext.mobileView, sharedContext.tabletView, sharedContext.desktopView]);

  useEffect(() => {
    function resizeIframes() {
      if (document.getElementsByClassName("iframe-all").length >= 1) {
        resizeIframe(
          "iframe-contain-desk",
          "iframe-desk",
          "iframe-scroll-limit-desk"
        );
        // resizeIframe('iframe-contain-zoom', 'iframe-zoom', 'iframe-scroll-limit-zoom');
        if (
          document.getElementsByClassName("iframe-all").length > 1 &&
          AppJson.portfolio[portfolioid].responsive
        ) {
          resizeIframe(
            "iframe-contain-tablet",
            "iframe-tablet",
            "iframe-scroll-limit-tablet"
          );
          resizeIframe(
            "iframe-contain-mobile",
            "iframe-mobile",
            "iframe-scroll-limit-mobile"
          );
        }
      }
    }

    // Handle the responsive scaling of iframed portfolio content
    function resizeIframe(sibClass, ifClass, ifRatio) {
      let ifSibling = document.getElementsByClassName(sibClass); //target the iframe container
      let ifSiblingWidth = ifSibling[0].clientWidth; //get the width of the iframe conatiner
      let ifSiblingWidthN = parseInt(ifSiblingWidth, 10); //remove "px" from 'ifSiblingWidth'
      let ifIframe = document.getElementsByClassName(ifClass); //target the iframe
      let ifScrollLimitC = document.querySelector("." + ifClass); //target the iframe container (again but diferently)
      // let ifWidthRatio = AppJson.portfolio[portfolioid].widthDesktop;
      let ifWidthRatio = ifScrollLimitC.dataset.widthRatio; //Get the 'widthRatio' dataset from the iframe conatainer
      let ifIframeScaleCalc = (ifSiblingWidthN / ifWidthRatio).toFixed(5); //Calculate the correct scale for the iframe
      ifIframe[0].style.transform =
        "translateX(-50%) scale(" + ifIframeScaleCalc + ")"; //Apply the coreect scale to the iframe
    }

    function timer() {
      var resizeId = setTimeout(resizeIframes, 5);
    }

    timer();
    // resizeIframes();
    window.addEventListener("resize", timer);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", timer);
    };
  }, [portfolioid]); 

  if (AppJson.portfolio[portfolioid].responsive) {
    var mobileDevices = (
      <React.Fragment>
        <div className="tablet-contain" ref={tabletGroupRef}>
          <img
            className="mockupImg"
            src={require("../assets/ui/Tablet-Shadow.svg")}
            alt="Mockup-Tablet Shadow"
          />
          <img
            className="mockupImg"
            src={require("../assets/ui/Tablet.svg")}
            alt="Mockup-Tablet"
          />
          <Iframer
            portfolioid={portfolioid}
            cnameIF="iframe-all iframe-tablet"
            cnameIFSL="iframe-scroll-limit iframe-scroll-limit-tablet"
            cnameIFC="iframe-contain iframe-contain-tablet"
            dwidth={AppJson.portfolio[portfolioid].widthTablet}
            dlength=".8"
          />
        </div>
        <div className="mobile-contain" ref={mobileGroupRef}>
          <img
            className="mockupImg"
            src={require("../assets/ui/Mobile-Shadow.svg")}
            alt="Mockup-Mobile Shadow"
          />
          <img
            className="mockupImg"
            src={require("../assets/ui/Mobile.svg")}
            alt="Mockup-Mobile"
          />
          <Iframer
            portfolioid={portfolioid}
            cnameIF="iframe-all iframe-mobile"
            cnameIFSL="iframe-scroll-limit iframe-scroll-limit-mobile"
            cnameIFC="iframe-contain iframe-contain-mobile"
            dwidth={AppJson.portfolio[portfolioid].widthMobile}
            dlength=".8"
          />
        </div>
      </React.Fragment>
    );
  } else {
    const mobileDevices = null;
  }
  return (
    <div className="mockup">
      <div className="mockup-contain" ref={mockupRef}>
        <MockupBG className="mockupImg" alt="Mockup-Background" />
        <div className="desktop-contain" ref={desktopGroupRef}>         
          <img
            className="mockupImg"
            src={require("../assets/ui/Desktop-Shadow.svg")}
            alt="Mockup-Desktop Shadow"
          />
          <img
            className="mockupImg"
            src={require("../assets/ui/Desktop.svg")}
            alt="Mockup-Desktop"
          />
          <Iframer
            portfolioid={portfolioid}
            cnameIF="iframe-all iframe-desk"
            cnameIFSL="iframe-scroll-limit iframe-scroll-limit-desk"
            cnameIFC="iframe-contain iframe-contain-desk"
            dwidth={AppJson.portfolio[portfolioid].widthDesktop}
            dlength=".8"
          />
        </div>
        {mobileDevices}
      </div>
    </div>
  );
};

export default PortfolioMockup;
