import React, { useRef, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import AppJson from "../data/App.json";
import { gsap, Power1 } from "gsap";
import { useTransitionHistory } from "react-route-transition";
// import PanelContext from "../context/PanelContext";

const GridItem = () => {
  // const griditem = [];
  const timeline = useMemo(() => gsap.timeline({ paused: true }), []);
  const griditem = [useRef(null)];
  const history = useTransitionHistory();
  useEffect(() => {
    timeline.staggerFrom(
      griditem,
      0.3,
      {
        // display: "none",
        opacity: 0,
        delay: 0.25,
        scale: 0.5,
        ease: Power1.easeInOut,
      },
      0.02
    );

    timeline.play();
  }, [timeline]);
  return (
    <>
      {AppJson.portfolio.map((portfolio) => {
        return (
          <div
            ref={(div) => (griditem[portfolio.index] = div)}
            key={portfolio.index}
            className="grid-item"
          >
            <img
              src={"../assets/portfolio/" +
                portfolio.pageUrl +
                "/assets/thumbnail/" +
                portfolio.thumbSrc +
                "-300.png"}
              alt={portfolio.thumbAlt}
            />


            {/* <img srcSet={
                ("../assets/portfolio/" +
                  portfolio.pageUrl +
                  "/assets/thumbnail/" +
                  portfolio.thumbSrc +
                  "-500.png 500w",
                "../assets/portfolio/" +
                  portfolio.pageUrl +
                  "/assets/thumbnail/" +
                  portfolio.thumbSrc +
                  "-300.png 300w",
                "../assets/portfolio/" +
                  portfolio.pageUrl +
                  "/assets/thumbnail/" +
                  portfolio.thumbSrc +
                  "-100.png 100w")
              }
              src={
                "../assets/portfolio/" +
                portfolio.pageUrl +
                "/assets/thumbnail/" +
                portfolio.thumbSrc +
                "-500.png"
              }
              sizes="
              (max-width:500px)  100px,
              (max-width:800px)  300px,
              (max-width:3000px) 500px, 
              500px"
              alt={portfolio.thumbAlt}
            /> */}

            <Link
              className="thumbs"
              to={{
                pathname: "/work/" + portfolio.pageUrl,
                state: {
                  fromindex: portfolio.index,
                },
              }}
              onClick={(e) => {
                e.preventDefault();
                history.push("/work/" + portfolio.pageUrl);
              }}
            ></Link>
          </div>
        );
      })}
    </>
  );
};

export default GridItem;
