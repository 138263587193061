import React, { useRef, useEffect, useMemo } from "react";
import { gsap } from "gsap";
import { useHistory } from "react-router-dom";
import AppJson from "../data/App.json";
import { FiChevronLeft } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";
// import { FiInfo } from "react-icons/fi";
import Button from "./Button";

function PortfolioInfobar(props) {
  const timeline = useMemo(() => gsap.timeline({ paused: true }), []);
  const infoRef = useRef(null);

  useEffect(() => {
    timeline.fromTo(infoRef.current, {
      opacity: 0,
    },{
      opacity: 1,
      duration: 1,
      repeat:-1,
      repeatDelay: 1,
      yoyo: false,
    });
    timeline.play();
  }, [timeline]);

  useEffect(() => {
    const navBack = document.querySelector(".infobar .nav-left");
    const navNext = document.querySelector(".infobar .nav-right");
    if (portfolioid == 0) {
      navBack.classList.add("disabled");
    } else if (portfolioid == portfolioLength - 1) {
      navNext.classList.add("disabled");
    }
  }, []);

  const portfolioid = props.portfolioid;
  const history = useHistory();
  const currentPage = Number(portfolioid);
  const newPageForward = currentPage + 1;
  const newPageBackward = currentPage - 1;
  const portfolioLength = Object.keys(AppJson.portfolio).length;

  function navigateForward() {
    if (portfolioid < portfolioLength - 1) {
      history.push("/work/" + AppJson.portfolio[newPageForward].pageUrl);
    }
  }

  function navigateBackward() {
    if (portfolioid > 0) {
      history.push("/work/" + AppJson.portfolio[newPageBackward].pageUrl);
    }
  }

  function infoDisplay() {
    var x = document.querySelector(".info");
    if (x.style.display === "none" || x.style.display === "") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  function infoDisplaynew() {
    var x = document.querySelector(".info");
    x.classList.toggle("showme");
  }

  return (
    <div className="infobar">
      <Button
        buttonStyle="buttons nav-left"
        type="button"
        onClick={navigateBackward}
      >
        <FiChevronLeft />
      </Button>
      <h1 onClick={infoDisplay}>
        {AppJson.portfolio[portfolioid].title}
        {/* <FiInfo /> */}
        <svg ref={infoRef} stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="8"></line></svg>
      </h1>
      <Button
        buttonStyle="buttons nav-right"
        type="button"
        onClick={navigateForward}
      >
        <FiChevronRight />
      </Button>
    </div>
  );
}

export default PortfolioInfobar;
