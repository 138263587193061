import React from "react";

function Button({ children, type, onClick, buttonStyle }) {
  return (
    <button
      className={buttonStyle + " " + "button-all"}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default Button;

// import React from 'react';

// export default React.forwardRef((props, ref) => {
  
//   return     <button
//   className={props.buttonStyle + " " + "button-all"}
//   type={props.type}
//   onClick={props.onClick}
//   ref={ref}
// >
//   {props.children}
// </button>;
// });



