import React from "react";
import { ReactComponent as BurgerIcon } from "../assets/ui/buttons/Burger.svg";

function mobileNav() {
  var x = document.querySelector(".content-block .nav");
  if (x.style.display == "none" || x.style.display === "") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}

const Burger = (props) => {
  return (
    <div className="burger-icon" style={props.style} >
      <BurgerIcon
        className="buttons launch-m-nav"
        onClick={mobileNav}
        alt="Navigation"
      />
    </div>
  );
};

export default Burger;
