import React, { useRef, useMemo, useEffect } from "react";
import { gsap } from "gsap";
import PortfolioInfo from "./PortfolioInfo";
import PortfolioInfobar from "./PortfolioInfobar";
import PortfolioMockup from "./PortfolioMockup";
import MockupMenu from "./MockupMenu";
import FolioNav from "./FolioNav";
import { useTransition } from "react-route-transition";
import AppJson from "../data/App.json";

const PortfolioPageGroup = (props) => {
  const portfolioid = props.from;
  const timeline = useMemo(() => gsap.timeline(), []);
  // const timelineFade = useMemo(() => gsap.timeline({ paused: true }), []);
  // const timelineNoFade = useMemo(() => gsap.timeline({ paused: true }), []);
  const portfoliopagegroupRef = useRef(null);
  const portfolioPagesAR = AppJson.portfolio.map((portfolio) => {
    return "/portfolio/" + portfolio.pageUrl;
  });

  useEffect(() => {
    timeline.to(portfoliopagegroupRef.current, {
      opacity: 1,
      duration: 1,
    });
    timeline.play();
  }, [timeline]);

  useTransition({
    handlers: [
      {
        from: ["/", "/portfolio", "/about", "/contact", "/*"],
        to: portfolioPagesAR,
        onEnter: async () => {
          console.log("Main Page to a PortfolioPage");
          await timeline.to(portfoliopagegroupRef.current, {
            duration: 1,
            opacity: 1,
            // y: 150,
          });
        },
      },

      {
        from: portfolioPagesAR,
        to: ["/", "/portfolio", "/about", "/contact", "/*"],
        onLeave: async () => {
          console.log("PortfolioPage to a Main Page");
          await timeline.to(portfoliopagegroupRef.current, {
            duration: 1,
            opacity: 0,
            // x: -150,
          });
        },
      },

      {
        from: portfolioPagesAR,
        to: portfolioPagesAR,
        onEnter: async () => {
          console.log("PortfolioPage-EnterFrom Another");
          await timeline.to(portfoliopagegroupRef.current, {
            duration: 0,
            opacity: 1,
            // y: 150,
          });
        },
      },
      {
        from: portfolioPagesAR,
        to: portfolioPagesAR,
        onLeave: async () => {
          console.log("PortfolioPage-LeaveTo Another");
          await timeline.to(portfoliopagegroupRef.current, {
            duration: 0,
            opacity: 1,
            // x: -150,
          });
        },
      },
    ],
  });

  return (
    <div ref={portfoliopagegroupRef} className="portfolio-group">
      <PortfolioInfo portfolioid={portfolioid}></PortfolioInfo>
      <PortfolioInfobar portfolioid={portfolioid}></PortfolioInfobar>
      <PortfolioMockup portfolioid={portfolioid}></PortfolioMockup>
      <MockupMenu portfolioid={portfolioid}></MockupMenu>
      <FolioNav portfolioid={portfolioid}></FolioNav>
      {/*<PortfolioZoom from={props.from}></PortfolioZoom>*/}
    </div>
  );
};

export default PortfolioPageGroup;
