import { createContext } from 'react';

const Contextall = createContext({
  mobileView: false,
  toggleMobileView: () => {},
  tabletView: false,
  toggleTabletView: () => {},
  desktopView: false,
  toggleDesktopView: () => {},
  // testContext: false,
  // testContextTrue: () => {},
  // testContextFalse: () => {},
});

export default Contextall;