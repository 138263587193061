import React, { useRef, useEffect, useMemo } from "react";
import AboutProfile from "./AboutProfile";
import AboutSkills from "./AboutSkills";
import { useTransition } from "react-route-transition";
import { gsap } from "gsap";


const AboutGroup = (props) => {
  const aboutgroupRef = useRef(null);
  const timeline = useMemo(() => gsap.timeline({ paused: true }), []);

  useEffect(() => {
    timeline.to(aboutgroupRef.current, {
      opacity: 1,
      duration: 1,
    });
    timeline.play();
  }, [timeline]);

  useTransition({
    handlers: [
      {
        path: '/about',
        onEnter: async () => {
          console.log('About-Enter');
          await timeline.to(aboutgroupRef.current, {
            duration: 1,
            opacity: 1,
            // y: -150,
            // scale:.9,
          })
        },
        onLeave: async () => {
          console.log('About-Leave');
          await timeline.to(aboutgroupRef.current, {
            duration: 1,
            opacity: 0,
            // x: -150,
            // scale:1,
          })
        },
      },
    ],
  })

  return (
<div ref={aboutgroupRef} className="about-group">
  <AboutProfile></AboutProfile>
  <AboutSkills></AboutSkills>
    </div>
  );
};

export default AboutGroup;